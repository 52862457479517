html,
body {
  margin: 0;
  height: 100%;
  overflow: hidden;
}

body {
  margin: 0;
  background-color: #ffffff;
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

@media screen and (max-width: 600px) {
  button {
    text-transform: uppercase;
  }
}

div.picker-item-selected {
  color: #ff8800 !important;
  font-weight: 600 !important;
}

div.picker-highlight {
  background-color: rgba(128, 128, 128, 0.2);
}

div.picker-highlight::before,
div.picker-highlight::after {
  height: 2px !important;
  background-color: #ff8800 !important;
}
